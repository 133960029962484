/*! themes */

.b1 {
	background-color: #231F20;
}
.b2 {
	background-color: #5664D2;
}
.c1,
.c1 span,
.c1 a {
	color: #1E2666;
}
.c2,
.c2 span,
.c2 a {
	color: #5664D2;
}
p a {
	color: #5664D2;
}
::selection,
.text-selection {
	color: #fff !important;
	-webkit-text-fill-color: #fff !important;
}
::-moz-selection {
	color: #ffffff !important;
}
img::selection,
img::-moz-selection {
	background: transparent;
}
p a:hover {
	color: #fff;
}

/*! fonts */

@font-face {
	font-family: 'ClashGrotesk';
	src: url('./fonts/clash/ClashGrotesk-Variable.woff2') format('woff2'),
		url('./fonts/clash/ClashGrotesk-Variable.woff') format('woff'),
		url('./fonts/clash/ClashGrotesk-Variable.ttf') format('truetype');
	font-weight: 200 700;
	font-display: swap;
	font-style: normal;
}

/*! defaults */

body, html {
	height: 100%;
	min-height: 100%;
	font-size: 20px; /* Please modify Generic Font Size Responsive in last rows */
	line-height: 1.5rem;
	color: #fff;
}
body {
	position: relative;
	background-color: #231F20;
	width: inherit;
	height: inherit;
}
body, table, input, select, textarea, pre {
	font-family: 'ClashGrotesk', sans-serif;
	font-weight: 400;
	color: #fff;
}
div, section, span, p, a {
	position: relative;
}
span, a, p {
	color: #fff;
}

/* Table */
table {
	border: 0;
	border-collapse: collapse;
	width: 100%;
}
table caption {
	font-size: 1rem;
}
table colgroup col {
	width: 29.572650%;
}
table colgroup col:first-child {
	width: 5.470085%;
}
table thead {
	border-bottom: solid 2px #BFBFBF;
}
table thead th {
	padding-top: 6px;
	padding-bottom: 6px;
	text-align: left;
}
table tfoot td {
	border-top: solid 1px #BFBFBF;
	padding-top: 6px;
	padding-bottom: 6px;
}
table tbody td {
	border-left: 2px solid #FFFFFF;
	border-right: 2px solid #FFFFFF;
	padding-top: 6px;
	padding-bottom: 6px;
}
table tr * {
	padding-left: 1%;
	padding-right: 1%;
}

/* List */
ul li {
	left: 0;
	text-indent: -4px;
	padding-left: 30px;
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid;
	padding-bottom: 8px;
}
ul li::before {
	content: '';
	position: relative;
	display: inline-block;
	background: #ffffff;
	height: 0.3rem;
	width: 0.3rem;
	top: -0.25rem;
	left: -21px;
	border-radius: 50%;
}
.c2 ul li::before {
	background: #939393;
}
ul p {
	display: inline;
}

/*! center */

.center {
	position: relative;
	margin: 0 auto;
}
.center1620,
.center1420 {
	position: relative;
	padding-left: 5%;
	padding-right: 5%;
	margin: 0 auto;
	width: 100%;
}
.center1620 {
	max-width: calc(1620px + 10%);
}
.center1420 {
	max-width: calc(1420px + 10%);
}

/*! typography */
.clash, 
.clash span {
	font-family: 'ClashGrotesk', sans-serif !important;
}

.t1, .t1 a, 
.t1 span, .t1 li {
	font-family: 'ClashGrotesk', sans-serif;
	font-size: 9rem;
	line-height: 9rem;
	color: #fff;
	font-weight: 400;
	text-transform: uppercase;
	font-style: normal;
	letter-spacing: 0.2rem;
}
.t5, .t5 a, 
.t5 span, .t5 li {
	font-family: 'ClashGrotesk', sans-serif;
	font-size: 1.3rem;
	line-height: 100%;
	color: #fff;
	font-weight: 400;
	text-transform: uppercase;
	font-style: normal;
	letter-spacing: 0.25rem;
}
.font-large, .font-large p, .font-large a {
	font-size: 3.1rem;
	line-height: 125%;
	font-weight: 400;
}
.font-medium, .font-medium p, .font-medium a {
	font-size: 1.4rem;
	line-height: 1.8rem;
	font-weight: 400;
	letter-spacing: 0.15rem;
}
.font-default, .font-default p, .font-default span, legend, select, input, textarea {
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
	font-style: normal;
}

.font-small, .font-small p, .font-small a, .font-small div {
	font-size: 15px;
	line-height: 22px;
	font-weight: 400;
}

/*! responsive */

@media screen and (max-width: 1650px) {
	.t1, .t1 a, 
	.t1 span, .t1 li {
		font-size: 8.1rem;
		line-height: 8.1rem;
	}
}

@media screen and (max-width: 1450px) {
	.t1, .t1 a, 
	.t1 span, .t1 li {
		font-size: 7.4rem;
		line-height: 7.4rem;
	}
}

@media screen and (max-width: 1225px) {
	body, html {
		font-size: 19px;
	}
}

@media screen and (max-width: 1125px) {
	.t1, .t1 a, 
	.t1 span, .t1 li {
		font-size: 6.7rem;
		line-height: 6.7rem;
	}
	.font-medium, .font-medium p, .font-medium a {
		font-size: 1.7rem;
		line-height: 2.08rem;
	}
}

@media screen and (max-width: 950px) {
	body, html {
		font-size: 18px;
	}
}

@media screen and (max-width: 850px) {
	.t1, .t1 a, 
	.t1 span, .t1 li {
		font-size: 5.8rem;
		line-height: 5.8rem;
	}
	.font-medium, .font-medium p, .font-medium a {
		font-size: 1.6rem;
		line-height: 1.98rem;
	}
}

@media screen and (max-width: 700px) {
	body, html {
		font-size: 17px;
	}
	ul li {
		padding-left: 20px;
	}
	ul li::before {
		left: -15px;
	}
}

@media screen and (max-width: 600px) {
	.t1, .t1 a, 
	.t1 span, .t1 li {
		font-size: 5rem;
		line-height: 5rem;
	}
	.font-medium, .font-medium p, .font-medium a {
		font-size: 1.5rem;
		line-height: 1.88rem;
	}
}

@media screen and (max-width: 500px) {
	.t1, .t1 a, 
	.t1 span, .t1 li {
		font-size: 4.2rem;
		line-height: 4.2rem;
	}
	body, html {
		font-size: 16px;
	}
	.font-medium, .font-medium p, .font-medium a {
		font-size: 1.4rem;
		line-height: 1.78rem;
	}
}

@media screen and (max-width: 425px) {
	.t1, .t1 a, 
	.t1 span, .t1 li {
		font-size: 3.8rem;
		line-height: 3.8rem;
	}
	body, html {
		font-size: 15px;
	}
	.font-medium, .font-medium p, .font-medium a {
		font-size: 1.35rem;
		line-height: 1.73rem;
	}
}


/* --------------------------------------------- END Generic Font Size Responsive END --------------------------------------------- */

/* --------------------------------------------- END Responsive END --------------------------------------------- */