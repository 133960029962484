html, body,
* {
	position: relative;
    cursor: none !important;
	-ms-overflow-style: none;
  	scrollbar-width: none; 
}
body::-webkit-scrollbar {
  display: none;
}
body.loading {
	overflow: hidden !important;
}

/*! cursor */

.mouse {
	position: fixed;
	display: none;
	width: 0px;
	height: 0px;
	overflow: visible;
	z-index: 99999;
	pointer-events: none;
}
.cursor {
	position: absolute;
	width: 14px;
	height: 14px;
	left: 50%;
    top: 50%;
	border-radius: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	border-radius: 50%;
	background-color: #fff;
	opacity: 0.7;
}

/*! hero */

.hero {
	height: 100vh;
	width: 100%;
}
.hero .videoWrap {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50% , -50%);
	width: 50vmin;
	height: 50vmin;
	overflow: hidden;
	opacity: 0.9;
}
.hero .video {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50% , -50%);
	width: 100vmax;
	height: 110vmax;
}
.hero .thumbnail {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
	z-index: 2;
	filter: grayscale(1);
	background: grey;
}
.hero iframe {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 200vh;
	min-width: 100%;
	height: 100%;
	pointer-events: none;
}
.hero .top {
	position: absolute;
	top: 20vh;
	height: 60vh;
	width: 90%;
	left: 50%;
	transform: translateX(-50%);
	z-index: 2;
}
.hero .name {
	font-size: 11vmin;
	line-height: 100%;
}
.hero .firstname {
	margin-right: 1vmin;
}
.hero .bottom {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
}
.hero .coordinates {
	margin-top: 1rem;
}
.hero .latitude {
	margin-left: 16px;
}
.hero .slogan {
	opacity: 0;
}
@media (orientation: portrait) {
	.hero .videoWrap {
		width: 50vh;
		height: 50vh;
		max-width: 80vw;
	}
}
@media screen and (max-width: 800px) {
	.hero .top {
		top: 50vh;
		height: auto;
		transform: translate(-50%, -50%);
	}
	.hero .bottom {
		display: none;
	}
	.hero .videoWrap {
		width: calc(100vw - 40px);
		height: calc(100vh - 40px);
		max-width: inherit;
	}
}

/*! showcase */

.showcase {
	width: 100%;
	height: 100vh;
}
.showcase .pin {
	width: 100%;
	height: 100%;
}

/*! spotlight */

.spotlight {
	width: 100%;
	margin-top: -110vh;
	height: 100vh;
}
.pin-spacer-spotlight-pin {
	height: inherit !important;
} 
.spotlight .video {
	position: absolute;
	overflow: hidden;
	width: 60vw;
	height: 70vh;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
}
.spotlight iframe {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 200vh;
	min-width: 100%;
	height: 100%;
	pointer-events: none;
}
.spotlight .overlay {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
	width: 100%;
	height: 100%;
	z-index: 2;
	pointer-events: none;
}
.spotlight .background {
	width: 100%;
	height: 100%;
	opacity: 0.5;
}
.spotlight .text {
	position: absolute;
	width: 52vw;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
	z-index: 1;
}

/*! footer */

footer {
	width: 100%;
}
footer .mail {
	font-size: 5.5vw;
	line-height: 5.5vw;
}