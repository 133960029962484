// ---------- CONFIGURATION ---------- //

$paddings: 400, 300, 256, 200, 180, 160, 128, 96, 80, 64, 56, 40, 34, 32, 24, 16, 8, 4;
$margins: 400, 300, 256, 200, 180, 160, 128, 96, 80, 64, 56, 40, 34, 32, 24, 16, 8, 4;
$breakpoints: 1650 .9, 1200 .8, 950 .7, 700 .6, 500 .5, 400 .4;
$multiplier: 0.65;

// ------ END CONFIGURATIONS END ------ //

/*-------------- PADDINGS --------------*/
@each $padding in $paddings {
	.p-tb-#{$padding} {
		padding-top: #{$padding}px;
		padding-bottom: #{$padding}px;
	}
	.p-t-#{$padding} {
		padding-top: #{$padding}px;
	}
	.p-b-#{$padding} {
		padding-bottom: #{$padding}px;
	}
	// @if $padding < 100 {	
	// 	.p-all-#{$padding} {
	// 		padding: #{$padding}px;
	// 	}
	// }
	@each $breakpoint, $reduction in $breakpoints {
		@media screen and (max-width: #{$breakpoint}px) {
			@if $padding < 100 {
				.p-tb-#{$padding} {
					padding-top: #{round($padding * $reduction)}px;
					padding-bottom: #{round($padding * $reduction)}px;
				}
				.p-t-#{$padding} {
					padding-top: #{round($padding * $reduction)}px;
				}
				.p-b-#{$padding} {
					padding-bottom: #{round($padding * $reduction)}px;
				}
			}
			@if $padding > 100 {
				.p-tb-#{$padding} {
					padding-top: #{round($padding * ($reduction * $multiplier))}px;
					padding-bottom: #{round($padding * ($reduction * $multiplier))}px;
				}
				.p-t-#{$padding} {
					padding-top: #{round($padding * ($reduction * $multiplier))}px;
				}
				.p-b-#{$padding} {
					padding-bottom: #{round($padding * ($reduction * $multiplier))}px;
				}
			}
		}
	}
}

/*-------------- MARGINS --------------*/
$min-margin: 32;

@each $margin in $margins {
	// Ensure margin values smaller than $min-margin are set to $min-margin
	$margin-value: if($margin < $min-margin, $min-margin, $margin);

	.m-tb-#{$margin} {
		margin-top: #{$margin}px;
		margin-bottom: #{$margin}px;
	}
	.m-t-#{$margin} {
		margin-top: #{$margin}px;
	}
	.m-b-#{$margin} {
		margin-bottom: #{$margin}px;
	}

	@each $breakpoint, $reduction in $breakpoints {
		@media screen and (max-width: #{$breakpoint}px) {
			@if $margin-value < 100 {
				.m-tb-#{$margin-value} {
					margin-top: #{round($margin-value * $reduction)}px;
					margin-bottom: #{round($margin-value * $reduction)}px;
				}
				.m-t-#{$margin-value} {
					margin-top: #{round($margin-value * $reduction)}px;
				}
				.m-b-#{$margin-value} {
					margin-bottom: #{round($margin-value * $reduction)}px;
				}
			}
			@if $margin-value > 100 {
				.m-tb-#{$margin-value} {
					margin-top: #{round($margin-value * ($reduction * $multiplier))}px;
					margin-bottom: #{round($margin-value * ($reduction * $multiplier))}px;
				}
				.m-t-#{$margin-value} {
					margin-top: #{round($margin-value * ($reduction * $multiplier))}px;
				}
				.m-b-#{$margin-value} {
					margin-bottom: #{round($margin-value * ($reduction * $multiplier))}px;
				}
			}
		}
	}
}